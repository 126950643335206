import { createReducer, on } from '@ngrx/store';
import { initialState, AuthState } from './auth.state';
import { AuthActions } from './auth.actions';

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state: AuthState) => {
    return {
      ...state,
      isLoggedIn: true,
    };
  }),
  on(AuthActions.logoutSuccess, (state: AuthState) => {
    return {
      ...state,
      isLoggedIn: false,
    };
  }),
  on(AuthActions.signupSuccess, (state: AuthState) => {
    return {
      ...state,
      isLoggedIn: true,
    };
  })
);
