import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HomepageComponent } from './homepage/homepage.component';
import { routes } from './app.routes';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FaqComponent } from './faq/faq.component';
import { HomeSalesRefinancingComponent } from './home-sales-refinancing/home-sales-refinancing.component';
import { BatterySolutionsComponent } from './battery-solutions/battery-solutions.component';
import { FormsComponent } from './forms/forms.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CareersComponent } from './careers/careers.component';
import { LicensesComponent } from './licenses/licenses.component';
import { NewsComponent } from './news/news.component';
import { EnvironmentalCommoditiesMarketsComponent } from './environmental-commodities-markets/environmental-commodities-markets.component';
import { ServiceChargesComponent } from './service-charges/service-charges.component';
import { FleetManagementComponent } from './fleet-management/fleet-management.component';
import { AboutComponent } from './about/about.component';
import { ProComponent } from './pro/pro.component';
import { PortalFaqComponent } from './portal-faq/portal-faq.component';
import { SpruceServicingComponent } from './spruce-servicing/spruce-servicing.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PopUpComponent } from './pop-up/pop-up.component';
import { NotificationBannerComponent } from './notification-banner/notification-banner.component';
import { CommonModule, NgIf } from '@angular/common';


@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    NgIf,

    HomepageComponent,
    NotificationBannerComponent,
    PopUpComponent,
    RouterModule,
    HeaderComponent,
    FooterComponent,
    FaqComponent,
    HomeSalesRefinancingComponent,
    BatterySolutionsComponent,
    FormsComponent,
    MonitoringComponent,
    DashboardComponent,
    CareersComponent,
    LicensesComponent,
    NewsComponent,
    EnvironmentalCommoditiesMarketsComponent,
    ServiceChargesComponent,
    AboutComponent,
    ProComponent,
    PortalFaqComponent,
    SpruceServicingComponent

  ],
})

export class AppComponent {}
