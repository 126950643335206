import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AuthState } from '../state/auth/auth.state';
import { Store } from '@ngrx/store';
import { AuthActions } from '../state/auth/auth.actions';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomAuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private store: Store<AuthState>
  ) {}

  canActivate(): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      map((isAuthenticated: any) => {
        if (isAuthenticated) {
          return true;
        } else {
          this.router.navigate(['']);
          return false;
        }
      })
    );
  }
}
